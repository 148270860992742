$text-link: #2997ff;
$text-color: #444;
$text-light: #999;

$logo-green-1: #B5F9AA;
$logo-font-color: #4F4242;

$background:  #dfdad3;
$border: #ccc;

$navBarHeight: 100px;