.App {
    background-color: $background;
}
.grid-image {
    
    -webkit-transition: .3s ease-in-;
    transition: .3s ease-in;
}

.grid-image-container:hover .grid-image {

        opacity: 0.3;
        // -webkit-transform: scale(1.05);
        // -ms-transform: scale(1.05);
        // transform: scale(1.05);
       
    
}

.img-hover-text {
    visibility: hidden;
    -webkit-transition: .3s ease-in-;
    transition: .3s ease-in;
}

.grid-image-container:hover .img-hover-text {
    visibility: visible;
}