
a:hover {
    text-decoration: none;
}


.box-item:hover, p:hover {
    text-decoration: none;
    
}

// .box-item :hover {
//     text-decoration: none;
//     -webkit-transition: background 3s ease-in-out;
// 	transition: background 3s ease-in-out;
//      background: linear-gradient(180deg, transparent 0%, transparent 18%, rgba(0,0,0,0.8) 99%, rgba(0,0,0,0.8) 100%)

// }

