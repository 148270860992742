@import "./_variable";
@import "./_navigation";
@import "./blog";
@import "./home";

@import "./post-masonry";

@import "./masonry-posts";
@import "./tags-row";
@import "./_post-grid";
@import "./edit-post";
@import "./create-post";
@import "./home2";
@import "./processes";
@import "./scrollUp";

html body {
  font-family: interstate, sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 25;
  margin: 0;
  background-color: $background;
  padding-top: $navBarHeight;
  position: relative;
  min-height: 100%;
  color: black;
  max-width: 100vw;
  overflow-x: hidden;
}

h1 {
  font-size: 2rem;
}

.row {
  padding-right: 9%;
  padding-left: 9%;
}

.overlay::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 1;
  -webkit-transition: background 3s ease-in-out;
  transition: background 3s ease-in-out;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
