.site-navigation {
  background: white;
  opacity: 1;
  height: $navBarHeight;
  width: 100%;
  display: flex;
  justify-content: center;
  color: $text-color;
  position: fixed;
  z-index: 100;
  top: 0;

  .ion-ios-menu {
    font-size: 36px;
    width: 65px;
    align-items: center;
    cursor: pointer;
    display: none;
  }

  .menu-content-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0%;
  }

  .coffee-time {
    display: flex;
    align-items: center;

    padding-left: 20vw;
    margin-right: 30px;
  }

  .menu-title {
    font-weight: 500;
    font-size: 22px;
    height: 100%;
    display: flex;
    align-items: left;
    margin-left: 30px;
  }

  ul {
    height: 100%;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
    margin: 0 20px 0 50px;
    text-decoration: none;

    li {
      text-decoration: none;
      padding: 0 20px 0 20px;
      font-size: 15px;
      color: $text-color;
      text-decoration: none;

      a:hover {
        color: $text-link;
        text-decoration: none;
      }
    }
    button {
      &:hover {
        color: $text-link;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .site-navigation {
    background: white;
    opacity: 1;
    height: $navBarHeight;
    width: 100%;
    display: flex;
    justify-content: center;
    color: $text-color;
    position: fixed;
    z-index: 100;
    top: 0;

    .ion-ios-menu {
      font-size: 36px;
      width: 65px;
      align-items: center;
      cursor: pointer;
      display: none;
    }

    .menu-content-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 0%;
    }

    .coffee-time {
      display: flex;
      align-items: center;

      padding-left: 20vw;
      margin-right: 30px;
    }

    .menu-title {
      font-weight: 500;
      font-size: 22px;
      height: 100%;
      display: flex;
      align-items: left;
      margin-left: 30px;
    }

    ul {
      height: 100%;
      margin-block-start: 0;
      margin-block-end: 0;
      padding-inline-start: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      list-style-type: none;
      margin: 0 20px 0 50px;
      text-decoration: none;

      li {
        text-decoration: none;
        padding: 0 10px 0 10px;
        font-size: 15px;
        color: $text-color;
        text-decoration: none;
        width: 50%;

        a:hover {
          color: $text-link;
          text-decoration: none;
        }
      }
      button {
        &:hover {
          color: $text-link;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .logo-image {
    display: none;
  }
  .site-navigation {
    justify-content: space-between;
    padding-left: 30px;

    .coffee-time {
      padding-top: 30px;
      padding-left: 45px;
    }
    .menu-content-container {
      width: 300px;
      height: calc(100% - 100px);
      background: white;
      position: fixed;
      top: 100px;
      left: -300px;
      transition: 300ms ease left;
      flex-direction: column-reverse;
      z-index: 999999;

      ul {
        flex-direction: column;
        justify-content: flex-start;

        li {
          text-align: left;
          height: 65px;
          display: flex;
          align-items: center;
        }
        a {
          padding: 20px 15px;
          width: 100%;
        }

        button {
          margin-top: 10%;
          text-align: left;
          height: 65px;

          display: flex;
          align-items: center;
          width: 100%;
          padding: 0px 35px;
        }
      }

      ul,
      li,
      .menu-avatar-container {
        width: 100%;
      }

      .ant-avatar {
        align-self: flex-start;
        margin-left: 30px;
      }
    }
    &.active {
      .menu-content-container {
        left: 0px;
      }
    }
    .ion-ios-menu {
      display: flex;
    }
  }
}
